export const config = {
  app: {
    version: 'v0.9',
    foot: '@gekogold 2024 ',
    state: 'public'
  }
}

export var env = {};
if ( config.app.state === 'local' ) {
  env = {
    api: {
      endpoint: {
        feed  : 'http://localhost:80/ss.geko/public/api/FeedApiConnector.php',
        cert  : 'http://localhost:80/ss.geko/public/api/CertApiConnector.php',
        shop  : 'http://localhost:80/ss.geko/public/api/ShopApiConnector.php'
      }
    }
  }
} else {
  env = {
    api: {
      endpoint: {
        feed  : 'https://feed.gekogold.com/new_api/FeedApiConneector.php',
        cert  : 'https://feed.gekogold.com/new_api/CertApiConnector.php',
        shop  : 'https://feed.gekogold.com/new_api/ShopApiConnector.php',
      }
    }
  }
}

export function texts ( which ) {
  switch ( which ) {
    
    case 'scan-before':
      return (
        <>
        clicca sul <span className='fo-gbb'>QR CODE</span> qui sopra per avviare
        la scansione del certificato con il
        tuo dispositivo
        </>
      );
      break;

    case 'scan-during':
      return (
        <>
        posiziona il QR Code del tuo certificato vicino alla telecamera.
        Sentirai un suono quando il certificato sarà scansionato.
        </>
      );
      break;

    case 'scan-verify':
      return (
        <>
        Verifica certificato ...
        </>
      );
      break;

    default:
      return (<> ... </>);
      break;
  }
}
