import { useEffect, useState }    from 'react';
import { useCookies }             from 'react-cookie';
import secureLocalStorage         from 'react-secure-storage';
import { useNavigate, useParams } from 'react-router-dom';

// views
import { config, texts } from '../config';

// actions
import Alert    from '../d_actions/Alert';
import Loading  from '../d_actions/Loading';
import Fetcher  from '../d_actions/Fetcher';

// imgs
import mainLogo from '../d_images/mainLogoAdmin.png';

export default function Splash ()
{
  const nav = useNavigate();
  const { cert } = useParams();

  const [ notify, setNotify ] = useState( null );
  const [ loading, setLoading ] = useState( true );
  const [ todoMsg, setTodoMsg ] = useState( '...' );

  const [ cookies, setCookies, removeCookies ] = useCookies(['certop']);

  let userName = secureLocalStorage.getItem( 'userName' );
  if ( userName == null || userName === '' ) {
    userName = cookies.userName;
  }

  let userAuth = secureLocalStorage.getItem( 'userAuth' );
  if ( userAuth == null || userAuth === '' ) {
    userAuth = cookies.userAuth;
  }

  useEffect(() => {
    // if has user data, check auth
    if ( userName == null || userAuth == null ) {

      setNotify({
        type:'error',
        msg:'le tue credenziali non sono sufficenti per accedere al servizio'
      });

      setTodoMsg( 'non sei un amministratore.' );
      setLoading( false );

    } else {

      setTodoMsg( 'benvenuto' );
      setNotify({
        type:'success',
        msg:'credenziali amministratore accettate'
      });

      // check if authorized and send to certificate in case.
      Fetcher({
        op: 'cert-operation-auth',
        userName: userName,
        userAuth: userAuth
      }, 'cert' ).then( re => {
        if ( re.status === 'ok' ) {
          setLoading( false );
          setNotify({ type:'success', msg:'admin approvato' });
          if ( cert != null ) {
            let trimCert = cert.substring( 0, 8 );
            nav( '/cert/' + trimCert );
          } else {
            setTodoMsg( 'non hai scansionato nessun token.' );
          }
        }
      }).catch( er => {
        setNotify({ type:'error', msg:'dati admin errati' });
      });
    }
  }, [ cert ]);

  return (
    <>

      <div className='cont-main'>

        <img className='logo-top' alt='geko' src={ mainLogo } />

        <h3 className='todo-msg'>{ todoMsg }</h3>

        <div className="simple-foot">{ config.app.foot }<span className='fo-gt co-gold'>{ config.app.version }</span></div>

        <Alert current_alert={ notify } />
        <Loading state={ loading } />
      </div>
    </>
  );

}
