import { env } from "../config";

export default function Fetcher ( call, feed ) {

  let _feed = null;

  switch ( feed ) {
    case 'cert':
      _feed = env.api.endpoint.cert;
      break;

    case 'shop':
      _feed = env.api.endpoint.shop;
      break;
  
    default:
      _feed = env.api.endpoint.feed;
      break;
  }

  // abort in case of empty call
  if ( call == null || call === {} ) {
    return( 'no call data aborting' );
  }

  const _headers = { "Content-Type":"application/json" };
  const verbose = false;

  if (verbose) { console.log( call ); }

  return fetch( _feed, {
    method: 'POST',
    headers:  _headers,
    body: JSON.stringify( call )

  }).then( res => {
    return res.json();    // pass result as json to second step
    
  }).then(data => {
    if (verbose) { console.log( data ); }
    return( data );      // return data object.
  })
  .catch(err => {
    return( err );        // return error
  });
}
