import { useEffect, useState } from "react";

export default function Alert ({ current_alert })
{
  const [ show, setShow ] = useState( false );

  useEffect(() => {
    if ( current_alert != null && current_alert.type !== 'ok' ) {
      setShow( true );
    }
    setTimeout( () => { setShow(false) }, 5000 );
  }, [ current_alert ] );

  return (
    <>
    { show === true && current_alert &&
      <div
        className={ `alert alert-${current_alert.type}` } >
        { current_alert.msg }
      </div>
    }
    </>
  )
}
