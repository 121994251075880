import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { config } from '../config';
import secureLocalStorage from 'react-secure-storage';
import Fetcher from '../d_actions/Fetcher';
import mainLogo from '../d_images/mainLogoAdmin.png';
import Alert from '../d_actions/Alert';

export default function Login () {
  const Nav = useNavigate();
  const [ cookies, setCookies, removeCookies ] = useCookies(['certop']);

  const [ userName, setUserName ] = useState( '' );
  const [ userAuth, setUserAuth ] = useState( '' );
  const [ notify, setNotify ] = useState( null );

  return (
    <>
    <div className='cont-main'>
      <img className='logo-top' alt='geko' src={ mainLogo } />

      <div className='gg-form-container'>
        <input className='gg-input' type='text' onChange={ (e) => user_change( e.target.value ) } />
        <input className='gg-input' type='password' onChange={ (e) => psw_change( e.target.value ) } />
        <button className='gg-button' onClick={ () => { login() } }>log in</button>
      </div>

        <Alert current_alert={ notify } />

      <div className="simple-foot">{ config.app.foot }<span className='fo-gt co-gold'>{ config.app.version }</span></div>
    </div>
    </>
  );

  function user_change ( value )
  { setUserName( value ); }

  function psw_change ( value )
  { setUserAuth( value ); }

  function login ( )
  {
    if ( userName === '' || userAuth === '' ) {
      setNotify({ type:'error', msg:'no user data' });
      return;
    }

    Fetcher({
      op: 'cert-operation-auth',
      userName: userName,
      userAuth: userAuth
    }, 'cert' ).then( re => {
        if ( re.status === 'ok' ) {
          secureLocalStorage.setItem( 'userName', userName );
          secureLocalStorage.setItem( 'userAuth', userAuth );

          setCookies( 'userName', userName, { domain:'.gekogold.com' } );
          setCookies( 'userAuth', userAuth, { domain:'.gekogold.com' } );

          setNotify({ type:'success', msg:'logged' });

          Nav( '/' );
          return;
          // check if admin and redirect
          // check if authorized and send to certificate in case.
        } else {
          setNotify({ type:'error', msg:re.response });
          return;
        }
      // redirect to splash
    }).catch( er => {
      setNotify({ type:'error', msg:'wrong user data' });
    });
  }

}
