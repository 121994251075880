// views
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { config } from '../config';
// view utils
import NavigatorBottom from './Navigator_Bottom';
import { friendlyToken, certinfo } from '../utils';
import BgSlide from './Bg_Slide';
// imgs
import bigLoading from '../d_images/bigLoading.webp';
import mainLogo from '../d_images/mainLogo.webp';
import CryptoPill from '../d_frames/CryptoPill';
import BigButton from '../d_frames/BigButton';
import DataLine from '../d_frames/DataLine';
// actions
import CertFunctions from '../action/CertFunctions';
import Alert from '../d_actions/Alert';
import secureLocalStorage from 'react-secure-storage';
import Fetcher from '../d_actions/Fetcher';

export default function Certificate_Edit ( )
{
  const nav = useNavigate();
  const { cert } = useParams();
  const [ cookies, setCookies, removeCookies ] = useCookies(['certop']);

  const [ certData, setCertData ] = useState({  });
  const [ notify, setNotify ] = useState({  });
  const [ loading, setLoading ] = useState( true );
  const [ todoMsg, setTodoMsg ] = useState( '...' );

  let userName = secureLocalStorage.getItem( 'userName' );
  if ( userName == null || userName === '' ) {
    userName = cookies.userName;
  }

  let userAuth = secureLocalStorage.getItem( 'userAuth' );
  if ( userAuth == null || userAuth === '' ) {
    userAuth = cookies.userAuth;
  }


  useEffect(() => {
    //
    // if has user data, check auth
    if ( userName == null || userAuth == null ) {
      nav( '/' );
    } else {

      setTodoMsg( 'modifica stato certificato' );

      Fetcher({
          op: 'cert-operation-auth',
          userName: userName,
          userAuth: userAuth
        }, 'cert'
      ).then( re => {
        if ( re.status === 'ok' ) {
          if ( cert != null ) {
            let trimCert = cert.substring( 0, 8 );

            Fetcher({
              op: 'get-certificate',
              certID: trimCert
            }, 'cert' ).then( re => {

              setCertData( JSON.parse(re.result) );
            }).catch( er => {

              setNotify({ type:'error', msg:'non trovo il certificato' });
            });

          } else {
            setTodoMsg( 'errore token certificato' );
          }
        }
      }).catch( er => {
        setNotify({ type:'error', msg:'dati admin errati' });
      });
    }

  }, [ cert, loading ]);

  return (
    <>
      { certData &&
        <>
          <BgSlide offset={ -100 }/>
          <div className='cont-main'>

            <img className='logo-top' alt='geko' src={ mainLogo } />

            { alert.type === 'pending' ?

              <>
                <div className='logo-center'>
                  <img className='App-logo' alt='geko' src={ bigLoading } />
                </div>
              </>

              :

              <>
                <div className='cont-slider'>
                  <div className='data-center'>

                    <div className='section-title'> operazioni lingotto: </div>
                    { certData.status !== 'ko' &&
                      <DataLine title='Seriale :' data={ friendlyToken( certData.cryptoID, 8 ).slice(0,19) + ' ...' } />
                    }

                    <div className='button-row'>

                      <BigButton
                        text='attiva certificato'
                        color='bg-success'
                        _action={ () => activate( certData.cryptoID ) }
                      />

                      <BigButton
                        text='disattiva certificato'
                        color='bg-danger'
                        _action={ () => deactivate( certData.cryptoID ) }
                      />

                    </div>

                    <div className='section-divider'></div>

                    <div className='section-title'>Stato corrente</div>

                    { certData.status !== 'ko' &&
                      <CryptoPill weight={ certData.weight } type='Oro' state={ certData.state }/>
                    }

                  </div>
                </div>

                < NavigatorBottom helpText={ todoMsg } />
              </>
            }

            < Alert current_alert={ notify } />

            <div className="simple-foot">{config.app.foot}<span className='fo-gt co-gold'>{config.app.version}</span></div>
          </div>
        </>
      }
    </>
  );

  function activate ( cryptoID )
  {
    setLoading( true );
    Fetcher({
      op: 'activate-certificate',
      certID: cryptoID
    }, 'cert' ).then( re => {
      if ( re.status === 'ok' ) {
        setNotify({ type:'success', msg:'certificato attivato' });
      } else {
        setNotify({ type:'error', msg:'errore attivazione' });
      }
      setLoading( false );
    }).catch( er => {
      setNotify({ type:'error', msg:'non trovo il certificato' });
    });
  }

  function deactivate ( cryptoID )
  {
    setLoading( true );
    Fetcher({
      op: 'deactivate-certificate',
      certID: cryptoID
    }, 'cert' ).then( re => {
      if ( re.status === 'ok' ) {
        setNotify({ type:'success', msg:'certificato disattivato' });
      } else {
        setNotify({ type:'error', msg:'errore disattivazione' });
      }
      setLoading( false );
    }).catch( er => {
      setNotify({ type:'error', msg:'non trovo il certificato' });
    });
  }
}
