import loadingIcon from '../d_images/smallLoading.webp';

export default function Loading ({ state })
{
  return (
    <>
    { state === true &&
        <div className='big-loading'>
            <img className='loading-icon' alt='loading...' src={ loadingIcon }/>
        </div>
    }
    </>
  );
}
